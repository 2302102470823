import React from "react"
import Masonry from "react-masonry-component"

const Services = () => {
  return (
    <section className="projects mt4 p0 bg-dark">
      <div className="row">
        <Masonry>
          <div className="col-md-4 col-sm-6  project" data-filter="People">
            <div className="image-tile inner-title  text-center">
              <a href="/senior">
                <img alt="Pic" className="lazyload" src="img/services/1.jpg" />
                <div className="title">
                  <h5 className="uppercase mb0">Graduate</h5>
                  <span>Portraits</span>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 project" data-filter="Animals">
            <div className="image-tile inner-title text-center">
              <a href="/gallery/weddings">
                <img
                  alt="Pic"
                  className="lazyload"
                  data-src="img/services/2.jpg"
                />
                <div className="title">
                  <h5 className="uppercase mb0">Events</h5>
                  <span>Weddings, Concerts & Games</span>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 project" data-filter="People">
            <div className="image-tile inner-title  text-center">
              <a href="/featured">
                <img
                  alt="Pic"
                  className="lazyload"
                  data-src="img/services/3.jpg"
                />
                <div className="title">
                  <h5 className="uppercase mb0">Portraits</h5>
                  <span>Beauty & Fashion</span>
                </div>
              </a>
            </div>
          </div>
        </Masonry>
      </div>
    </section>
  )
}

export default Services
