import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import Layout from "../../components/Layout"
import About from "./components/about"
// components
import Hero from "./components/hero"
import Preamble from "./components/preamble"
import Services from "./components/services"
import Portfolio from "./components/portfolio"
import Share from "../../components/Share"
import CTA from "./components/cta"
import SingleAbout from "./components/singleAbout"
import { defaultPortfolio } from "../../../defaultData"

const HomePage = () => {
  const query = useStaticQuery(graphql`
    query {
      contentfulPageData(slug: { eq: "home" }) {
        title
        subtitle
        thumbnail {
          title
          file {
            url
          }
        }
        mainContent {
          mainContent
        }
        supportContent {
          supportContent
        }
        ctaText
        ctaButtonText
        ctaLink
        ctaHeading
        otherFiles {
          title
          file {
            url
          }
        }
      }
      contentfulGallery(slug: { eq: "home-page" }) {
        pictures {
          description
          fluid(maxWidth: 500, maxHeight: 500, quality: 50, toFormat: WEBP) {
            src
          }
          file {
            url
          }
        }
      }
    }
  `)
  const [photoIndex, setPhotoIndex] = React.useState(0)

  const [lightBoxOpen, setLightBoxOpen] = React.useState(false)

  const {
    contentfulPageData: data,
    contentfulGallery: { pictures },
  } = query

  const gallery = pictures.map(p => {
    return {
      src: p?.file.url,
      title: p?.description,
      thumb: p?.fluid.src,
      full: p?.file.url,
    }
  })

  let aboutPics = data?.otherFiles.filter(
    k => k.title.toLowerCase().includes("about") && k
  )

  const images = defaultPortfolio?.map(m => {
    return m.src
  })

  const slides = data?.otherFiles.filter(k =>
    k.title.toLowerCase().includes("slide")
  )

  const showImageInLightBox = index => {
    setPhotoIndex(index)
    setLightBoxOpen(true)
  }

  return (
    <Layout>
      <Hero slides={slides} />
      {aboutPics.length > 1 ? (
        <About
          title={data.title}
          mainContent={data.mainContent}
          bioPic={data.thumbnail}
          aboutPics={aboutPics}
        />
      ) : (
        <SingleAbout
          title={data.title}
          mainContent={data.mainContent.mainContent}
          bioPic={data.thumbnail}
        />
      )}
      <Preamble text={data.supportContent.supportContent} />
      <Services />
      <Portfolio
        portfolio={gallery || defaultPortfolio}
        showImageInLightBox={showImageInLightBox}
      />
      <CTA
        text={data?.ctaText}
        buttonText={data?.ctaButtonText}
        link={data?.ctaLink}
        heading={data?.ctaHeading}
      />
      {lightBoxOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightBoxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageTitle={defaultPortfolio[photoIndex].subtitle}
        />
      )}
      <Share />
    </Layout>
  )
}

export default HomePage
