import React from "react"
import { withPrefix } from "gatsby"
import PropTypes from "prop-types"

import useScript from "../../../libs/useScripts"

const Hero = ({ slides }) => {
  useScript(withPrefix("js/particles.min.js"))
  useScript(withPrefix("js/app.js"))
  let slide1
  let slide2

  if (slides) {
    slide1 = slides[0].file.url
    slide2 = slides[1].file.url
  }

  return (
    <section className="cover fullscreen image-slider slider-paging-controls controls-inside border-thick">
      <ul className="slides">
        <li className="image-bg">
          <div className="background-image-holder">
            <div id="particles-js"></div>
            <img
              alt="Blacksburg photographer"
              className="background-image lazyload"
              src={slide1 || "./img/phot2.jpg"}
            />
          </div>
          <div className="hidden-xs v-align-transform p32">
            <div className="row">
              <div className="col-md-4 col-sm-6 ">
                <h1 className="hidden">Heart vs Logic</h1>
                <h2 className="uppercase bold mb0 white-text">Heart</h2>
                <h4 className="uppercase mb0 dark-text">vs</h4>
                <h2 className="uppercase bold mb0 white-text">Logic</h2>
              </div>
            </div>
          </div>
          <div className="visible-xs v-align-transform p32">
            <div className="row">
              <div className="col-md-4 col-sm-6 ">
                <h2 className="uppercase bold mb0 white-text">&nbsp; </h2>
                <h4 className="uppercase mb0 dark-text"> </h4>
                <h2 className="uppercase bold mb0 white-text"></h2>
              </div>
            </div>
          </div>
        </li>

        <li className="image-bg">
          <div className="background-image-holder">
            <div id="particles-js"></div>
            <img
              alt="photographer with camera backpack"
              className="background-image lazyload"
              src={slide2 || "img/phot1.jpg"}
            />
          </div>
          <div className="hidden-xs v-align-transform p32">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <h3 className="uppercase bold mb0">PORTRAIT & EVENTS</h3>
                <h4 className="uppercase">Photographer</h4>
              </div>
            </div>
          </div>
          <div className="visible-xs p32">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <h3 className="uppercase bold mb0 dark-text">
                  PORTRAIT & EVENTS
                </h3>
                <h4 className="uppercase bold">Photographer</h4>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  )
}

Hero.propTypes = {
  slides: PropTypes.array,
}

export default Hero
